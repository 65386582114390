<template>
  <div>
    <v-dialog
      content-class="create-general-memo-modal"
      v-model="editGeneralMemo"
      persistent
      scrollable
      max-width="960"
      origin="center center"
      overlay-opacity=".3"
    >
      <v-card id="EditGeneralMemo" class="height-95vh" v-if="singleDraft">
        <v-card-title class="pa-0">
          <v-toolbar flat>
            <v-toolbar-title>
              {{ $t("card.edit-general-memo") }}
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-tooltip
              bottom
              v-if="!$vuetify.breakpoint.smAndDown && userIsKrani"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :data-hint="$t('introjs.this-button-for-submit-action')"
                  data-hintposition="middle-middle"
                  data-position="bottom-right-aligned"
                  v-bind:disabled="!enableAction"
                  color="blue-grey"
                  class="ma-2 white--text"
                  @click="submitForm('submit')"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon dark class="mr-2">
                    {{ iconSubmit }}
                  </v-icon>
                  {{ $t("button.submit") }}
                </v-btn>
              </template>
              <span>{{ $t("tooltip.submit-to-upline") }}</span>
            </v-tooltip>

            <v-menu
              v-if="!$vuetify.breakpoint.smAndDown && !userIsKrani"
              bottom
              left
            >
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      v-bind:data-hint="
                        $t('introjs.this-button-for-submit-action')
                      "
                      data-hintposition="middle-middle"
                      data-position="bottom-right-aligned"
                      v-bind:disabled="!enableAction"
                      color="blue-grey"
                      dark
                      v-bind="attrs"
                      v-on="{ ...tooltip, ...menu }"
                    >
                      <v-icon>{{ iconSubmit }}</v-icon>
                      {{ $t("button.submit") }}
                    </v-btn>
                  </template>
                  <span>{{ $t("tooltip.select-submit-to") }}</span>
                </v-tooltip>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, i) in menus"
                  :key="i"
                  @click="menuAction(item.command)"
                >
                  <v-icon class="mr-3">{{ item.icon }}</v-icon>
                  <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind:data-hint="$t('introjs.this-button-for-save-as-draft')"
                  data-hintposition="middle-middle"
                  data-position="bottom-right-aligned"
                  v-bind:disabled="!enableAction"
                  color="blue-grey"
                  class="ma-2 white--text"
                  v-on:click="submitForm('save')"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon dark class="mr-2">
                    {{ iconSave }}
                  </v-icon>
                  {{ $t("button.update") }}
                </v-btn>
              </template>
              <span>{{ $t("tooltip.update-draft") }}</span>
            </v-tooltip>

            <v-menu v-if="$vuetify.breakpoint.smAndDown" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>{{ iconMenu }}</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, i) in mobileMenus"
                  v-bind:key="i"
                  v-on:click="menuAction(item.command)"
                >
                  <v-icon class="mr-3">{{ item.icon }}</v-icon>
                  <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="showHelper" v-on="on" v-bind="attrs">
                  <v-icon>{{ iconHelp }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("tooltip.help-me") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on:click="editGeneralMemo = false"
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon>{{ iconClose }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("tooltip.close-not-save") }}</span>
            </v-tooltip>
          </v-toolbar>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text height="100%">
          <v-row class="mt-3">
            <div
              class="pl-0 pr-0 col-sm-12 col-md-9"
            >
              <v-alert
                v-if="$v.form.kepada.$error && !$v.form.kepada.required"
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  bottom--left
                  mt-4
                  mb-0
                "
              >
                {{ $t("error.select-at-least-one-memo-recipient") }}
              </v-alert>
              <v-alert
                v-if="recipientSenderSimilar"
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  bottom--left
                  mt-4
                  mb-0
                "
              >
                {{ $t("error.recipient-and-direksi-must-not-have-the-same-items") }}
              </v-alert>

              <v-autocomplete
                v-bind:data-hint="$t('introjs.this-is-memo-recipient-selector')"
                data-hintposition="middle-middle"
                data-position="bottom-left-aligned"
                hide-details="true"
                v-model="form.kepada"
                v-bind:disabled="isUpdating"
                v-bind:items="recipientList"
                v-bind:menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                }"
                hide-selected
                deletable-chips
                small-chips
                v-bind:search-input.sync="searchInput"
                v-on:change="searchInput = ''"
                chips
                color="blue-grey lighten-2"
                v-bind:label="$t('label.select-memo-recipients')"
                item-text="nama"
                item-value="id"
                multiple
                clearable
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    v-bind:input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removePenerima(data.item)"
                  >
                    <v-avatar left>
                      <v-img
                        v-if="
                          data.item.kelompok != 'GROUP' && data.item.avatar_path
                        "
                        :src="data.item.avatar_path"
                      ></v-img>
                      <v-icon
                        v-else-if="
                          data.item.kelompok != 'GROUP' &&
                          !data.item.avatar_path
                        "
                        class="mr-2"
                      >
                        {{ iconUser }}
                      </v-icon>
                      <v-icon v-else class="mr-2">
                        {{ iconGroup }}
                      </v-icon>
                    </v-avatar>
                    {{ data.item.nama }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar>
                      <img
                        v-if="
                          data.item.kelompok != 'GROUP' && data.item.avatar_path
                        "
                        v-bind:src="data.item.avatar_path"
                      />
                      <v-icon
                        v-else-if="
                          data.item.kelompok != 'GROUP' &&
                          !data.item.avatar_path
                        "
                      >
                        {{ iconUser }}
                      </v-icon>
                      <v-icon v-else>
                        {{ iconGroup }}
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.nama"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.kelompok"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>

              <v-alert
                v-if="recipientCcSimilar"
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  top--left
                  mt-4
                  mb-0
                "
              >
                {{ $t("error.recipient-and-cc-must-not-have-the-same-items") }}
              </v-alert>
            </div>
            <div
              class="pa-0 vertical-all-child col-sm-12 col-md-3">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-on="on"
                    v-bind="attrs"
                    class="
                      avoore-icon-checkbox
                      checkbox--primary checkbox--share
                    "
                  >
                    <input id="chk-edit-cc" type="checkbox" v-model="hasCC" />
                    <label for="chk-edit-cc"></label>
                  </div>
                </template>
                <span>{{ $t("tooltip.add-cc") }}</span>
              </v-tooltip>

              <v-tooltip bottom v-if="userHQ && !form.is_masuk">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-on="on"
                    v-bind="attrs"
                    class="
                      avoore-icon-checkbox
                      checkbox--primary checkbox--user-tie
                      ml-2
                    "
                  >
                    <input
                      id="chk-edit-bom"
                      type="checkbox"
                      v-model="form.is_bom"
                    />
                    <label for="chk-edit-bom"></label>
                  </div>
                </template>
                <span>{{ $t("tooltip.directors-memo") }}</span>
              </v-tooltip>

              <v-tooltip bottom v-if="userSekper && !form.is_bom">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-on="on"
                    v-bind="attrs"
                    class="
                      avoore-icon-checkbox
                      checkbox--primary checkbox--inbox
                      ml-2
                    "
                  >
                    <input
                      id="chk-edit-masuk"
                      type="checkbox"
                      v-model="form.is_masuk"
                    />
                    <label for="chk-edit-masuk"></label>
                  </div>
                </template>
                <span>{{ $t("tooltip.incoming-letter") }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-on="on"
                    v-bind="attrs"
                    class="
                      avoore-icon-checkbox
                      checkbox--primary checkbox--eye
                      ml-4
                    "
                  >
                    <input
                      id="chk-edit-secret"
                      type="checkbox"
                      v-model="form.is_rahasia"
                    />
                    <label for="chk-edit-secret"></label>
                  </div>
                </template>
                <span>{{ $t("tooltip.this-memo-is-confidential") }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    v-on="on"
                    v-bind="attrs"
                    class="
                      avoore-icon-checkbox
                      checkbox--primary checkbox--hourglass
                      ml-2
                    "
                  >
                    <input
                      id="chk-edit-urgent"
                      type="checkbox"
                      v-model="form.urgensi"
                    />
                    <label for="chk-edit-urgent"></label>
                  </div>
                </template>
                <span>{{ $t("tooltip.this-memo-is-urgent") }}</span>
              </v-tooltip>
            </div>
          </v-row>

          <v-row class="mt-3" v-if="hasCC">
            <v-col cols="12" md="12" sm="12" class="pl-0 pr-0">
              <v-alert
                v-if="$v.form.tembusan.$error && !$v.form.tembusan.required"
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  bottom--left
                  mb-0
                  mt-4
                "
              >
                {{ $t("error.select-at-least-one-memo-cc") }}
              </v-alert>

              <v-alert
                v-if="recipientCcSimilar"
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  bottom--left
                  mb-2
                "
              >
                {{ $t("error.recipient-and-cc-must-not-have-the-same-items") }}
              </v-alert>

              <v-autocomplete
                v-bind:data-hint="
                  $t('introjs.this-is-memo-cc-recipient-selector')
                "
                data-hintposition="middle-middle"
                data-position="bottom-left-aligned"
                hide-details="true"
                v-model="form.tembusan"
                v-bind:disabled="isUpdating"
                v-bind:items="recipientList"
                v-bind:menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                }"
                hide-selected
                deletable-chips
                small-chips
                v-bind:search-input.sync="searchInput"
                v-on:change="searchInput = ''"
                chips
                color="blue-grey lighten-2"
                v-bind:label="$t('label.select-memo-cc')"
                item-text="nama"
                item-value="id"
                multiple
                clearable
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    v-bind:input-value="data.selected"
                    close
                    v-on:click="removeTembusan(data.item)"
                  >
                    <v-avatar left>
                      <v-img
                        v-if="
                          data.item.kelompok != 'GROUP' && data.item.avatar_path
                        "
                        v-bind:src="data.item.avatar_path"
                      ></v-img>
                      <v-icon
                        v-else-if="
                          data.item.kelompok != 'GROUP' &&
                          !data.item.avatar_path
                        "
                        class="mr-2"
                      >
                        {{ iconUser }}
                      </v-icon>
                      <v-icon v-else class="mr-2">
                        {{ iconGroup }}
                      </v-icon>
                    </v-avatar>
                    {{ data.item.nama }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar>
                      <img
                        v-if="
                          data.item.kelompok != 'GROUP' && data.item.avatar_path
                        "
                        v-bind:src="data.item.avatar_path"
                      />
                      <v-icon
                        v-else-if="
                          data.item.kelompok != 'GROUP' &&
                          !data.item.avatar_path
                        "
                      >
                        {{ iconUser }}
                      </v-icon>
                      <v-icon v-else>
                        {{ iconGroup }}
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.nama"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.kelompok"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row v-if="form.is_bom">
            <v-col cols="12" class="pl-0 pr-0">
              <v-alert
                v-if="$v.form.direksi.$error && !$v.form.direksi.required"
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  bottom--left
                  mb-0
                  mt-4
                "
              >
                {{ $t("error.select-exactly-one-director-sevp") }}
              </v-alert>

              <v-autocomplete
                v-bind:data-hint="$t('introjs.this-is-director-selector')"
                data-hintposition="middle-middle"
                data-position="bottom-right-aligned"
                v-model="form.direksi"
                v-bind:items="dirSevps"
                v-bind:filter="dirSevpsFilter"
                v-bind:menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                }"
                auto-select-first
                hide-details="true"
                item-text="nama"
                item-value="id"
                clearable
                label="Direktur/SEVP"
                placeholder="Direktur/SEVP"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.nama }}
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pl-0 pr-0">
              <v-alert
                v-if="$v.form.hal.$error && !$v.form.hal.required"
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  bottom--left
                  mt-4
                  mb-0
                  mt-4
                "
              >
                {{ $t("error.memo-subject-must-be-filled") }}
              </v-alert>
              <v-alert
                v-if="$v.form.hal.$error && !$v.form.hal.minLength"
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  bottom--left
                  mt-4
                  mb-0
                  mt-4
                "
              >
                {{
                  $t("error.memo-subject-min-length") +
                  " 10 " +
                  $t("label.characters")
                }}
              </v-alert>
              <v-alert
                v-if="$v.form.hal.$error && !$v.form.hal.maxLength"
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  bottom--left
                  mt-4
                  mb-0
                  mt-4
                "
              >
                {{
                  $t("error.memo-subject-max-length") +
                  " 250 " +
                  $t("label.characters")
                }}
              </v-alert>
              <v-text-field
                v-bind:data-hint="$t('introjs.this-is-memo-subject')"
                data-hintposition="middle-middle"
                data-position="bottom-right-aligned"
                v-model="form.hal"
                hide-details="true"
                maxlength="250"
                v-bind:hint="$t('tooltip.min-max-char-for-memo-subject')"
                v-bind:label="$t('label.memo-subject')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pl-0 pr-0">
              <v-alert
                v-if="$v.form.isi.$error && !$v.form.isi.required"
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  bottom--left
                  mb-4
                "
              >
                {{ $t("error.memo-message-must-be-filled") }}
              </v-alert>
              <v-alert
                v-if="$v.form.isi.$error && !$v.form.isi.minLength"
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  bottom--left
                  mt-4
                  mb-0
                  mt-4
                "
              >
                {{
                  $t("error.memo-message-min-length") +
                  " 27 " +
                  $t("label.characters")
                }}
              </v-alert>
              <v-alert
                v-if="$v.form.isi.$error && !$v.form.isi.maxLength"
                dense
                type="error"
                v-bind:icon="iconAlert"
                class="
                  modal-alert
                  v-alert--narrow v-alert--bubble
                  bottom--left
                  mt-4
                  mb-0
                  mt-4
                "
              >
                {{
                  $t("error.memo-message-max-length") +
                  " 120000 " +
                  $t("label.characters")
                }}
              </v-alert>
            </v-col>
            <froala-editor
              v-bind:data-hint="$t('introjs.this-is-memo-message')"
              data-hintposition="middle-middle"
              data-position="bottom-right-aligned"
              v-bind:oldEditorData="form.isi"
              v-on:editorData="changeEditorData"
              v-on:froalaIsWorking="froalaIsWorking"
              v-on:enableAction="setEnableAction"
              v-bind:editorDisabled="editorDisabled"
            ></froala-editor>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="12">
              <div
                class="attachment-list mt-4"
                v-if="
                  (form.attachments && form.attachments.length > 0) ||
                  (currAttachments && currAttachments.length > 0)
                "
              >
                <p
                  class="mb-1 text--avore-grey"
                  v-html="$t('text.new-attachment-list')"
                ></p>
                <ul>
                  <li
                    v-for="(item, index) in currAttachments"
                    v-bind:key="index"
                    v-bind:value="item.id"
                    class="file--list"
                  >
                    <a href="javascript:;" class="server--file">
                      <span>{{ index + 1 }}.</span>
                      {{ item.file_name }} - {{ item.file_size | formatSize }}
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="ml-2"
                            color="red"
                            v-on:click="confirmDeleteAttachment(item, index)"
                            v-on="on"
                            v-bind="attrs"
                          >
                            <v-icon>{{ iconDelete }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("tooltip.delete-this-file") }}</span>
                      </v-tooltip>
                    </a>
                  </li>
                  <li
                    v-for="(item, idx) in form.attachments"
                    v-bind:key="item.id"
                    v-bind:value="item.id"
                    class="file--list"
                  >
                    <a href="javascript:;">
                      <span>{{ idx + 1 + currAttachments.length }}.</span>
                      {{ item.name }} - {{ item.size | formatSize }}
                      <span v-if="item.error">{{ item.error }}</span>
                      <span v-else-if="item.success">success</span>
                      <span v-else-if="item.active">active</span>
                      <span v-else></span>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="ml-2"
                            color="red"
                            v-on:click="deleteAttachment(item)"
                            v-on="on"
                            v-bind="attrs"
                          >
                            <v-icon>{{ iconDelete }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("tooltip.delete-this-file") }}</span>
                      </v-tooltip>
                    </a>
                  </li>
                </ul>
              </div>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <file-upload
                    input-id="edit-general"
                    v-on="on"
                    v-bind="attrs"
                    class="
                      mt-4
                      att-button
                      v-btn v-btn--is-elevated v-btn--has-bg
                      v-size--default
                      blue-grey
                    "
                    post-action="/upload/post"
                    extensions="jpg,jpeg,png,pdf,doc,docx,xls,xlsx,zip,rar,ppt,pptx"
                    accept="image/png, image/jpeg, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation
                    , application/vnd.rar, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword,
                    application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip"
                    v-bind:multiple="true"
                    v-bind:size="1024 * 1024 * 35"
                    v-bind:active="true"
                    v-bind:maximum="totalAttCount"
                    v-model="form.attachments"
                    v-on:input-filter="inputFilter"
                    v-on:input-file="inputFile"
                    ref="upload"
                    v-bind:title="$t('tooltip.add-attachments')"
                  >
                    <v-icon class="text--white">{{ iconAttachment }}</v-icon>
                  </file-upload>
                </template>
                <span>{{ $t("tooltip.add-attachments") }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card v-if="!singleDraft" style="height: 100% !important">
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          width="100%"
          type="actions, article, list-item-three-line"
        ></v-skeleton-loader>
      </v-card>
    </v-dialog>
    <upline-selector
      v-bind:showUplineDialog="showUplineDialog"
      v-on:showHideUplineDialog="showHideUplineDialog"
      v-on:uplineSelected="uplineSelected"
    ></upline-selector>
    <peer-selector
      v-bind:showPeerDialog="showPeerDialog"
      v-on:showHidePeerDialog="showHidePeerDialog"
      v-on:peerSelected="peerSelected"
    ></peer-selector>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import froalaEditor from "./FroalaEditor";
import VueUploadComponent from "vue-upload-component";
import uplineSelector from "./UplineSelector.vue";
import peerSelector from "./PeerSelector.vue";
import {
  mdiContentSave,
  mdiMenu,
  mdiSend,
  mdiClose,
  mdiBookArrowUp,
  mdiAccountGroup,
  mdiAccount,
  mdiAlertCircleOutline,
  mdiHelpCircleOutline,
  mdiAttachment,
  mdiDeleteForever,
} from "@mdi/js";

import toolbarMenu from "@/constants/toolbarmenu";
import { validationMixin } from "vuelidate";
import introJS from "intro.js/intro.js";
import "intro.js/minified/introjs.min.css";

const createHelper = introJS();

const {
  required,
  requiredIf,
  minLength,
  maxLength,
} = require("vuelidate/lib/validators");

export default {
  name: "create-general-memo-modal",
  components: {
    froalaEditor,
    "file-upload": VueUploadComponent,
    uplineSelector,
    peerSelector,
  },
  data() {
    // const srcs = {
    //   1: "https://image.flaticon.com/icons/png/128/709/709722.png"
    // };
    return {
      iconSave: mdiContentSave,
      iconSubmit: mdiSend,
      iconSubmitUpline: mdiBookArrowUp,
      iconMenu: mdiMenu,
      iconClose: mdiClose,
      iconGroup: mdiAccountGroup,
      iconUser: mdiAccount,
      iconAlert: mdiAlertCircleOutline,
      iconHelp: mdiHelpCircleOutline,
      iconAttachment: mdiAttachment,
      iconDelete: mdiDeleteForever,
      menus: toolbarMenu.createDraftToolbarMenu,
      editorIsWorking: false,
      timeout: null,
      loader: null,
      enableAction: true,
      autoUpdate: true,
      isUpdating: false,
      searchInput: "",
      hasCC: false,
      totalAttSize: 0,
      totalAttCount: 5,
      rules: [(v) => v.length <= 500 || this.$t("tooltip.max-500-char")],
      subjectTextRules: [
        (v) => !!v || this.$t("tooltip.this-field-is-required"),
        (v) =>
          (v && v.length >= 10) || this.$t("tooltip.this-field-min-10-char"),
        (v) =>
          (v && v.length <= 250) || this.$t("tooltip.this-field-max-250-char"),
      ],
      currAttachments: [],
      form: {
        id: undefined,
        _method: undefined,
        action: "save",
        is_bom: undefined,
        is_masuk: undefined,
        parent_memo_id: undefined,
        direksi: [],
        is_rahasia: undefined,
        urgensi: undefined,
        attachments: undefined,
        kepada: [],
        hal: undefined,
        isi: undefined,
        share_to_revision: [],
        initiator_nik: undefined,
        tembusan: [],
        selectedUpline: undefined,
      },
      showPeerDialog: false,
      showUplineDialog: false,
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      kepada: { required },
      tembusan: {
        required: requiredIf(function () {
          return this.hasCC;
        }),
      },
      direksi: {
        required: requiredIf(function () {
          return this.form.is_bom;
        }),
      },
      hal: { required, minLength: minLength(10), maxLength: maxLength(250) },
      isi: { required, minLength: minLength(27), maxLength: maxLength(120000) },
    },
  },
  computed: {
    ...mapState("resources", ["memoRecipient", "dirSevps"]),
    ...mapState("draft", ["filterParams", "editParams", "singleDraft"]),
    ...mapState("user", ["currentUser"]),
    ...mapState(["helpGroupRecipientDialog", "tipToHide"]),
    userIsPjp() {
      if (!this.currentUser) return false;
      return ["PJP", "PJP_KANPUS"].includes(this.currentUser.usertype_elemen);
    },
    userIsKrani() {
      if (!this.currentUser) return false;
      return ["KRANI","SEKRETARIS_KRANI"].includes(this.currentUser.usertype_elemen);
    },
    userHQ() {
      if (!this.currentUser) return false;
      let unitHasBagian = ['00','10'];
      return unitHasBagian.includes(this.currentUser.kode_unit);
    },
    userSekper() {
      if (!this.currentUser) return false;
      return (
        this.currentUser.kode_unit === "00" &&
        this.currentUser.kode_bagian == "BAG237"
      );
    },
    mobileMenus() {
      if (["KRANI","SEKRETARIS_KRANI"].includes(this.currentUser.usertype_elemen)) {
        return toolbarMenu.createDraftToolbarMenuMobileKrani;
      } else {
        return toolbarMenu.createDraftToolbarMenuMobile;
      }
    },
    editGeneralMemo: {
      get() {
        return this.$store.state.draft.editGeneralMemo;
      },
      set(val) {
        this.$store.commit("draft/SET_EDIT_GENERAL_MEMO", val);
      },
    },
    recipientList() {
      if (!this.memoRecipient) return [];
      return this.memoRecipient;
    },
    recipientCcSimilar() {
      if (!this.form.kepada || !this.form.tembusan) return false;
      return this.similarItemExist(this.form.kepada, this.form.tembusan);
    },
    recipientSenderSimilar(){
      if (!this.form.kepada || !this.form.direksi) return false;
      return this.similarItemExist(this.form.kepada, this.form.direksi);
    },
    editorDisabled() {
      if (!this.form.kepada || !this.form.hal) return true;
      if (this.form.kepada.length > 0 && this.form.hal.length > 9) return false;
      return true;
    },
  },

  watch: {
    editGeneralMemo(val) {
      //empty oldData everytime modal is showing;
      if (!val) {
        return createHelper.hideHints();
      }
      this.formReset();
      // this.$v.$reset();
      //repopulate all backend data
      this.resetRecipientPeerUpline();

      this.getDataResource({
        source: "memo-recipients",
        tipe:'biasa',
        bgProcess: true,
      });
      this.getDataResource({
        source: "peer-revisers",
        bgProcess: true,
      });
      this.getDataResource({
        source: "atasan",
        bgProcess: true,
      });
      this.loadDraftDetail(this.editParams);
    },
    singleDraft(val) {
      if (!this.editGeneralMemo) return;
      if (!val) return;
      this.form = {
        id: val.memo.id_hash,
        _method: "put",
        action: "save",
        is_bom: val.memo.tipe ==='bom',
        is_masuk: val.memo.tipe === 'masuk',
        parent_memo_id: val.memo.parent_id,
        direksi: val.memo.direksi,
        is_rahasia: val.memo.tag.includes('rahasia'),
        urgensi: val.memo.tag.includes('rahasia'),
        attachments: [],
        kepada: this.mapId(val.penerima),
        hal: val.memo.hal,
        isi: val.memo.isi,
        share_to_revision: [],
        initiator_nik: val.memo.initiator_nik,
        tembusan: this.mapId(val.tembusan),
        selectedUpline: undefined,
      };
      this.currAttachments = val.memo_attachments;
      this.hasCC = val.tembusan && val.tembusan.length > 0;
      //count the attachment
      this.totalAttSize = 0;
      let attCount = 5;

      for (const [key, file] of Object.entries(val.memo_attachments)) {
        attCount = parseInt(key) + 1;
        this.totalAttSize += parseInt(file.size);
      }
      this.totalAttCount = attCount;
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => {
        this[l] = false;
        this.loader = null;
        this.editGeneralMemo = false;
      }, 3000);
    },
    tipToHide(val) {
      if (!val) return;
      createHelper.hideHint(val);
    },
  },
  methods: {
    ...mapActions("resources", ["resetRecipientPeerUpline", "getDataResource"]),
    ...mapActions(["setMessageBox"]),
    ...mapActions("draft", [
      "updateMyDraft",
      "loadDraft",
      "loadDraftDetail",
      "deleteServerAttachment",
    ]),
    changeEditorData(e) {
      this.form.isi = e;
    },
    showHideUplineDialog(val) {
      this.showUplineDialog = val;
    },
    removePenerima(item) {
      const index = this.form.kepada.indexOf(item.id);
      if (index >= 0) this.form.kepada.splice(index, 1);
    },
    removeTembusan(item) {
      const index = this.form.tembusan.indexOf(item.id);
      if (index >= 0) this.form.tembusan.splice(index, 1);
    },
    dirSevpsFilter(item, queryText) {
      const textOne = item.nama.toLowerCase();
      const textTwo = item.kode.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    formReset() {
      this.$v.$reset();
      this.hasCC = false;
      this.form = {
        id: undefined,
        _method: "PUT",
        action: "save",
        is_bom: undefined,
        is_masuk: undefined,
        parent_memo_id: undefined,
        direksi: [],
        is_rahasia: undefined,
        urgensi: undefined,
        attachments: undefined,
        kepada: [],
        hal: undefined,
        isi: undefined,
        share_to_revision: [],
        initiator_nik: undefined,
        tembusan: [],
        selectedUpline: undefined,
      };
      if (this.form.attachments && this.form.attachments.length > 0) {
        this.$refs.upload.clear();
      }
    },
    submitForm(action) {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      if (this.similarItemExist(this.form.kepada, this.form.tembusan)) {
        return this.setMessageBox({
          snackbar: false,
          show: true,
          type: "error",
          title: "error.error-title",
          message: "error.recipient-and-cc-must-not-have-the-same-items",
        });
      }
      if (this.similarItemExist(this.form.kepada, this.form.direksi)) {
        return this.setMessageBox({
          snackbar: false,
          show: true,
          type: "error",
          title: "error.error-title",
          message: "error.recipient-and-direksi-must-not-have-the-same-items",
        });
      }
      this.form.action = action;

      let finalData = this.clearFormData(this.form);

      switch (action) {
        case "submit":
          if (
            ["KRANI","SEKRETARIS_KRANI"].includes(this.currentUser.usertype_elemen) &&
            !this.form.initiator_nik
          ) {
            return (this.showUplineDialog = true);
            //open upline selector
          }
          this.form._method = undefined;
          this.updateMyDraft({
            memoId: this.form.id,
            action: this.form.action,
            formData: finalData,
          });
          this.$nextTick(() => {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              this.loadDraft(this.filterParams);
              this.$store.commit("SET_CREATE_GENERAL_MEMO", false);
            }, 500);
          });

          // if (!destination) {
          //   return this.setMessageBox({
          //     snackbar: true,
          //     show: true,
          //     type: "error",
          //     title: "error.error-title",
          //     message: "error.draft-submit-must-have-destinations-or-recipients",
          //   });
          // }
          break;
        case "share_to_revision":
          if (
            ["KARPIM", "KARPIM_KANPUS", "KASUBAG", "KADIS"].includes(
              this.currentUser.usertype_elemen
            ) &&
            !this.form.reviser_nik
          ) {
            return (this.showPeerDialog = true);
            //open upline selector if krani
          }
          this.finalData = this.clearFormData(this.form);

          this.axiosProcessing = true;

          this.updateMyDraft({
            memoId: this.form.id,
            action: "share_to_revision",
            formData: finalData,
          });
          this.$nextTick(() => {
            this.axiosProcessing = false;
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              this.$store.commit("SET_CREATE_GENERAL_MEMO", false);
            }, 500);
          });
          break;
        case "save":
          this.updateMyDraft({
            memoId: this.form.id,
            formData: finalData,
            _method: "PUT",
          });
          this.$nextTick(() => {
            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              this.$store.commit("SET_CREATE_GENERAL_MEMO", false);
            }, 500);
          });
          break;
        default:
          break;
      }
    },
    menuAction(command) {
      switch (command) {
        case "save":
          this.submitForm("save");
          break;
        case "submit":
          this.submitForm("submit");
          break;
        case "share_to_revision":
          this.submitForm("share_to_revision");
          break;
        case "help":
          this.showHelper();
          break;
        default:
          break;
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (
          (parseInt(newFile.file.size) >= 35000000 ||
          parseInt(newFile.file.size) + parseInt(this.totalAttSize) >=
            35000000)
        ) {
          this.$dialog.notify.error(
            this.$t("error.attachment-size-max-35-mb-or-5-files"),
            {
              position: "top-left",
              timeout: 3000,
            }
          );
          return prevent();
        }
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // add
        this.totalAttSize += parseInt(newFile.size);
        this.totalAttCount -= 1;
        if (this.form.attachments.length === 1) {
          return this.$dialog.notify.success(
            this.$t("msgbox.attachment-will-upload-on-submit-or-save"),
            {
              position: "top-left",
              timeout: 3000,
            }
          );
        }
      }
      if (newFile && oldFile) {
        // update
        return;
      }
      if (!newFile && oldFile) {
        // remove
        //send to backend to remove by file_id
        this.totalAttSize -= parseInt(oldFile.size);
        this.totalAttCount += 1;
        this.deleteAttachment(oldFile);
      }
    },
    confirmDeleteAttachment(file, index) {
      this.$dialog
        .confirm({
          text: this.$t("msgbox.are-you-sure-to-delete-this-attachment"),
          title: this.$t("msgbox.delete-attachment"),
          persistent: true,
          icon: this.iconQuestion,
          color: "danger",
          actions: [
            { text: this.$t("button.cancel"), color: "primary", key: false },
            { text: this.$t("button.yes-delete"), color: "red", key: true },
          ],
        })
        .then((result) => {
          if (result != true) return;

          let formData = {
            memoId: this.form.id,
            id_hash: file.id_hash,
            _method: "DELETE",
          };
          this.deleteServerAttachment(formData).then((result) => {
            if (result.data == true) {
              this.totalAttCount += 1;
              this.currAttachments.splice(index, 1);
            }
          });
        });
    },
    deleteAttachment(file) {
      if (!file) return;
      this.$refs.upload.remove(file);
    },
    showHelper() {
      setTimeout(() => {
        createHelper.setOptions({
          hintButtonLabel: this.$t("button.i-understand"),
        });
        createHelper.addHints();
        // createHelper.refresh();
        createHelper.showHints();
      }, 500);
    },
    uplineSelected(val) {
      if (!val) return;
      if (["KRANI","SEKRETARIS_KRANI"].includes(this.currentUser.usertype_elemen)) {
        this.form.initiator_nik = val.nik;
      } else {
        this.form.selectedUpline = val.nik;
      }
      this.submitForm("submit");
    },
    froalaIsWorking(val) {
      this.editorIsWorking = val;
    },
    setEnableAction(val) {
      this.enableAction = val;
    },
    showHidePeerDialog(val) {
      this.showPeerDialog = val;
    },
    peerSelected(val) {
      if (!val) return;
      this.form.reviser_nik = val.map(({ nik }) => nik).join();
      this.menuAction("share_to_revision");
    },
    mapId(obj) {
      if (!obj || obj.length === 0) return [];
      return obj.map(({ id }) => id);
    },
  },
  filters: {
    formatSize(size) {
      if (!size) return;
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + " MB";
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + " KB";
      }
      return size.toString() + " B";
    },
  },
};
</script>
<style lang="scss">
.v-dialog.v-dialog--active.v-dialog--persistent.create-general-memo-modal {
  height: 98vh !important;
  max-height: 100vh !important;
  .fr-element.fr-view {
    height: calc(100vh - 340px) !important;
    max-height: 100vh !important;
  }
}
@media only screen and (max-width: 767px) {
  .v-dialog.v-dialog--active.v-dialog--persistent.create-general-memo-modal {
    margin: 10px !important;
    .fr-element.fr-view {
      height: calc(100vh - 440px) !important;
      max-height: 100vh !important;
    }
  }
}

.width-max {
  margin: 10px !important;
}
.height-95vh {
  min-height: 98vh;
}
.position-absolute {
  position: absolute;
}
</style>
