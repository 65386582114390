<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-flex fill-height>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <BaseCard :height="'100%'" class="memo-inbox">
            <header-left slot="left-toolbar" @draftReload="draftReload" @deleteSelected="deleteSelected"
              @submitSelected="submitSelected" :selectedItems="selectedItems" :heading="$t('card.draft-title')"
              :enableAction="selectedItems.length > 0" />
            <header-right slot="right-toolbar" @draftReload="draftReload" :enableAction="selectedItems.length > 0" />
            <p v-if="
              (!myDraftList || myDraftList.length <= 0) &&
              !axiosLoading &&
              !networkError
            " class="align-center center-align">
              {{ $t("text.draft-empty") }}
            </p>

            <v-list :data-intro="$t('introjs.this-is-inbox-list')" two-line class="pa-1"
              v-if="!networkError && !axiosLoading && myDraftList">
              <template v-for="(item, index) in myDraftList">
                <v-subheader v-if="item.header" :key="item.header" v-text="$t(`${'label.' + item.header}`)">
                </v-subheader>

                <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>

                <v-list-item v-else :key="item.id_hash" @click.self="
                  showDraftDetail(item.id_hash, item.tipe, item.status_label)
                " v-ripple="{ class: `primary--text` }">
                  <label class="mr-2 check--blank" v-if="submitStatus(item.memo_status_id) === 'submitted'"></label>
                  <label v-if="
                    ['draft', 'pending'].includes(
                      submitStatus(item.memo_status_id)
                    )
                  " class="avoore-checkbox path d-block mr-2" v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                    :for="item.id_hash" :key="item.id_hash" :data-intro="$t('introjs.tick-checkbox-to-show-action')">
                    <input type="checkbox" class="d-inline-flex" v-model="selectedItems" @change="updateCheckAll"
                      :value="item.id_hash" :id="item.id_hash" />
                    <svg viewBox="0 0 21 21">
                      <path
                        d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186">
                      </path>
                    </svg>
                  </label>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-avatar v-on="on" v-bind="attrs" class="d-none d-sm-block mr-2" :data-intro="
                        $t('introjs.animated-avatar-meaning-not-readed')
                      " :class="{ blinking: !item.data_time }" @click="
                        showDraftDetail(item.id_hash, item.tipe, item.status_label)
                      ">
                        <v-icon v-if="
                          item.status_label === 'submitted'
                        " class="success--text">{{ iconSent }}</v-icon>
                        <v-icon v-if="item.status_label ==='draft'" class="warning--text">{{ iconEdit }}</v-icon>
                      </v-list-item-avatar>
                    </template>
                    <span>{{
                      item.status_label === 'submitted'
                      ? $t("text.draft-is-submitted")
                      : $t("text.edit-still-allowed")
                      }}</span>
                  </v-tooltip>

                  <v-list-item-content @click="
                    showDraftDetail(item.id_hash, item.tipe, item.status_label)
                  ">
                    <v-list-item-title>
                      <span :data-intro="$t('introjs.this-is-sender-name')" class="sub-headline"
                        :class="{ 'unread--title': !item.data_time }">
                        {{ cutJudul(item.subtitle, 150) }}
                      </span>
                      <div class="d-inline-flex align-self-center align-center align--vertical-center"
                        :data-intro="$t('introjs.badge-is-tag-for-memo-status')">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-if="parseInt(item.has_attachments) == 1" class="att-icon mx-1" color="primary"
                              dark v-bind="attrs" v-on="on">
                              {{ iconAttachment }}
                            </v-icon>
                          </template>
                          <span>{{ $t("tooltip.attachment-exist") }}</span>
                        </v-tooltip>

                        <div v-if="!$vuetify.breakpoint.smAndDown">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-if="item.tag.includes('rahasia')" class="att-icon mx-1" color="warning" dark
                                v-bind="attrs" v-on="on">
                                {{ iconSecret }}
                              </v-icon>
                            </template>
                            <span>{{ $t("tooltip.secret") }}</span>
                          </v-tooltip>
                        </div>

                        <v-tooltip bottom v-if="$vuetify.breakpoint.smAndDown">
                          <template v-slot:activator="{ on, attrs }">
                            <a v-bind="attrs" v-on="on" v-if="item.tag.includes('rahasia')" href="javascript:void(0)"
                              class="label theme-bg3 text-white f-12">{{ $t("badge.confidential") }}</a>
                          </template>
                          <span>{{ $t("tooltip.confidential") }}</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="$vuetify.breakpoint.smAndDown">
                          <template v-slot:activator="{ on, attrs }">
                            <a v-bind="attrs" v-on="on" v-if="item.tag.includes('urgent')" href="javascript:void(0)"
                              class="label theme-bg3 text-white f-12">{{ $t("badge.urgent") }}</a>
                          </template>
                          <span>{{ $t("tooltip.urgent") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a v-bind="attrs" v-on="on" v-if="item.tipe ==='bom'" href="javascript:void(0)"
                              class="label theme-bg4 text-white f-12">{{ $t("badge.directors-memo") }}</a>
                          </template>
                          <span>{{ $t("tooltip.directors-memo") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a v-bind="attrs" v-on="on" v-if="item.tipe ==='masuk'" href="javascript:void(0)"
                              class="label theme-bg6 text-white f-12">{{ $t("badge.incoming-letter") }}</a>
                          </template>
                          <span>{{ $t("tooltip.incoming-letter") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a v-bind="attrs" v-on="on" v-if="item.tipe ==='eksternal'"
                              href="javascript:void(0)" class="label theme-bg3 text-white f-12">{{
                              $t("badge.external-letter") }}</a>
                          </template>
                          <span>{{ $t("tooltip.external-letter") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a v-bind="attrs" v-on="on" v-if="item.tipe ==='izin_prinsip'"
                              href="javascript:void(0)" class="label theme-bg4 text-white f-12">{{
                              $t("badge.permit-memo") }}</a>
                          </template>
                          <span>{{ $t("tooltip.permit-memo") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a v-bind="attrs" v-on="on" v-if="item.tag.includes('urgent')" href="javascript:void(0)"
                              class="label theme-bg3 text-white f-12">{{ $t("badge.urgent") }}</a>
                          </template>
                          <span>{{ $t("tooltip.urgent") }}</span>
                        </v-tooltip>
                        
                      </div>
                    </v-list-item-title>
                    <v-list-item-subtitle :data-intro="$t('introjs.this-is-memo-number-and-title')"
                      class="d-none d-sm-block" :class="{ 'unread--title': !item.data_time }">
                      {{ `${cutJudul(item.title, 150)}` }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="d-block d-sm-none" :class="{ 'unread--title': !item.data_time }">
                      {{ cutJudul(item.title, 150) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-spacer></v-spacer>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-if="!$vuetify.breakpoint.smAndDown" class="text-right" v-on="on" v-bind="attrs"
                        :data-intro="$t('introjs.this-is-sending-date')"
                        @click="showSentDetail(item.id_hash, item.creator_nik)">
                        {{ $date(item.data_time).format("DD MMM YYYY") }}
                        <br />
                        {{ $date(item.data_time).format("HH:mm") }} WIB
                      </span>
                    </template>
                    <span>{{ item.data_time | diffForHumans }}</span>
                  </v-tooltip>
                </v-list-item>
              </template>
            </v-list>
            <v-card-text v-if="networkError || axiosLoading || !myDraftList">
              <v-skeleton-loader class="mx-auto" max-width="100%"
                type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line">
              </v-skeleton-loader>
            </v-card-text>
          </BaseCard>
        </v-col>
      </v-row>
    </v-flex>
    <upline-selector v-bind:showUplineDialog="showUplineDialog" v-on:showHideUplineDialog="showHideUplineDialog"
      v-on:uplineSelected="uplineSelected"></upline-selector>
    <edit-general-memo-modal></edit-general-memo-modal>
    <edit-permit-memo-modal></edit-permit-memo-modal>
    <component :is="editXLetter" :params="editXParams" @changeXParams="changeXParams"></component>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import headerLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import uplineSelector from "@/components/commonComponents/UplineSelector.vue";
import editGeneralMemoModal from "@/components/commonComponents/EditGeneralMemoModal";
import editPermitMemoModal from "@/components/commonComponents/EditPermitMemoModal";
import { isIntroActive } from "@/constants/config";
import dayjs from "dayjs";
import "intro.js/minified/introjs.min.css";

import {
  mdiAttachment,
  mdiStarCircle,
  mdiIncognito,
  mdiBriefcaseCheck,
  mdiClockFast,
  mdiSpeedometerSlow,
  mdiSpeedometerMedium,
  mdiSpeedometer,
  mdiCogTransfer,
  mdiEmailEditOutline,
  mdiEmailArrowRightOutline,
  mdiCheckboxBlankOutline,
  mdiHelpCircleOutline,
  mdiClose,
  mdiDeleteOutline,
} from "@mdi/js";

export default {
  components: {
    headerLeft,
    HeaderRight,
    uplineSelector,
    editGeneralMemoModal,
    editPermitMemoModal,
    'edit-x-letter': () => import('../../../../components/commonComponents/EditXLetterModal.vue'),
  },
  data: () => ({
    iconAttachment: mdiAttachment,
    iconUrgent: mdiClockFast,
    iconBom: mdiStarCircle,
    iconSecret: mdiIncognito,
    iconPermit: mdiBriefcaseCheck,
    iconSlow: mdiSpeedometerSlow,
    iconMedium: mdiSpeedometerMedium,
    iconFast: mdiSpeedometer,
    iconDirect: mdiCogTransfer,
    iconEdit: mdiEmailEditOutline,
    iconSent: mdiEmailArrowRightOutline,
    iconCheckBlank: mdiCheckboxBlankOutline,
    iconQuestion: mdiHelpCircleOutline,
    iconClose: mdiClose,
    iconDelete: mdiDeleteOutline,
    selectedItems: [],
    isCheckedAll: false,
    selectedUpline: undefined,
    showUplineDialog: false,
    timeout: null,
    editXLetter: 'edit-x-letter',
    editXParams: {
      show: false,
      editParams: {
        memoId: null,
      },
    }
  }),
  filters: {
    diffForHumans: (date) => {
      if (!date) {
        return "";
      }
      return dayjs(date).fromNow();
    },
  },
  computed: {
    ...mapState(["isMobile", "networkError"]),
    ...mapState("user", ["currentUser"]),
    ...mapState("draft", [
      "draftList",
      "axiosLoading",
      "filterParams",
      "selectAllDraft",
    ]),
    myDraftList() {
      if (!this.draftList) return [];
      return this.draftList.data;
    },
    memoIds() {
      if (!this.draftList) return [];
      var notSubmitted = this.draftList.data.filter(function (el) {
        // console.log(el.memo_status_id);
        // el.memo_status_id == '10' ||
        // el.memo_status_id != '20' ||
        // el.memo_status_id != '30'
        return [10, 20, 30].includes(parseInt(el.memo_status_id));
        // this.submitStatus(el.memo_status_id) == false;
      });
      // let notSubmitted = this.draftList.data.filter(el => !this.submitStatus(el.memo_status_id));
      // console.log(notSubmitted);
      return notSubmitted ? notSubmitted.map(({ id_hash }) => id_hash) : [];
    },
    
  },
  created() {
    let params = this.filterParams;
    params.currentPage = 1;
    this.setFilterParams(params);

    this.draftReload();
    this.getDataResource({
      source: "atasan",
      bgProcess: true,
    });
  },
  mounted() {
    if (isIntroActive === true) {
      const introJS = require("intro.js");
      setTimeout(() => {
        introJS
          .introJs()
          .setOptions({
            exitOnOverlayClick: false,
          })
          .start();
      }, 2000);
    }
  },
  watch: {
    selectAllDraft(val) {
      if (
        this.selectedItems.length == this.memoIds.length &&
        this.selectedItems.length > 1 &&
        val
      )
        return;
      if (
        this.selectedItems.length != this.memoIds.length &&
        this.selectedItems.length > 0 &&
        !val
      )
        return;

      this.selectedItems = this.removeFromArray(
        this.selectedItems,
        this.memoIds
      );

      if (val == true) {
        this.selectedItems = this.addToArray(this.selectedItems, this.memoIds);
      }
    },
  },
  methods: {
    ...mapActions("draft", [
      "loadDraft",
      "emptyDraft",
      "setFilterParams",
      "setAxiosLoading",
      "setSelectAllDraft",
      "deleteMyDraft",
      "submitDraftFromList",
      "setEditGeneralMemo",
      "setEditPermitMemo",
      "setSingleDraft",
    ]),
    ...mapActions("resources", ["getDataResource"]),
    showDraftDetail(idHash, tipe, status) {
      //beri jeda untuk animasi ripple
      setTimeout(() => {
        
       if (status === "submitted") {
          this.$router.push({
            name: "draft.detail",
            params: { id: idHash, canEdit: false },
          });
        } else if (status === "draft") {
         switch (tipe) {
            case "izin_prinsip":
               this.setSingleDraft(null);
               this.setEditPermitMemo({
               show: true,
               editParams: {
                  memoId: idHash,
               },
               });
               break;
            case "eksternal":
               this.setSingleDraft(null);
               this.loadDraftX({
               show: true,
               editParams: {
                  memoId: idHash,
               },
               });
               break;
            default:
               this.setSingleDraft(null);
               this.setEditGeneralMemo({
               show: true,
               editParams: {
                  memoId: idHash,
               },
               });
               break;
         }
        }
      }, 300);
    },
    draftReload() {
      // this.setFilterParams(this.filterParams);
      this.setSelectAllDraft(false);
      //remove dulu selected item
      this.selectedItems = this.removeFromArray(
        this.selectedItems,
        this.memoIds
      );

      this.emptyDraft();
      this.loadDraft(this.filterParams);

      // setTimeout(() => {
      //   this.overlay = false;
      // }, 3000);
    },
    showHideUplineDialog(val) {
      this.showUplineDialog = val;
    },
    uplineSelected(val) {
      if (!val) return;
      this.selectedUpline = val.nik;
      this.submitSelected();
    },
    deleteSelected() {
      // return this.$dialog.notify.success(
      //   this.$t("msgbox.delete-draft-success"),
      //   {
      //     position: "top-left",
      //     timeout: 5000,
      //   }
      // );

      this.$dialog
        .confirm({
          text: this.$t("msgbox.are-you-sure-to-delete-selected-draft"),
          title: this.$t("msgbox.delete-draft"),
          persistent: true,
          icon: this.iconQuestion,
          color: "danger",
          actions: [
            {
              text: this.$t("button.cancel"),
              color: "red",
              key: false,
              icon: this.iconClose,
            },
            {
              text: this.$t("button.yes-delete"),
              color: "primary",
              key: true,
              icon: this.iconDelete,
            },
          ],
        })
        .then((result) => {
          if (result !== true) return;
          else {
            let formData = {
              params: this.selectedItems.toString(),
              _method: "DELETE",
            };
            this.deleteMyDraft({ formData });
            this.$nextTick(() => {
              if (this.timeout) clearTimeout(this.timeout);
              this.timeout = setTimeout(() => {
                this.setSelectAllDraft(false);
                this.selectedItems = [];
                this.isCheckedAll = false;
                this.selectedUpline = undefined;
                this.loadDraft(this.filterParams);
              }, 500);
            });
          }
        });

      // this.$swal({
      //   title: this.$t("msgbox.delete-draft"),
      //   text: this.$t("msgbox.are-you-sure-to-delete-this-draft"),
      //   icon: "question",
      //   showCancelButton: true,
      //   confirmButtonText: this.$t("button.yes-delete"),
      //   cancelButtonText: this.$t("button.cancel"),
      //   confirmButtonColor: "#DE615E",
      //   customClass: {
      //     container: this.$vuetify.theme.dark ? "theme--dark" : "",
      //   },
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     console.log("hapus draft");
      //     let formData = {
      //       params: this.selectedItems.toString(),
      //       _method: "DELETE",
      //     };
      //     this.deleteMyDraft({ formData });
      //     this.$nextTick(() => {
      //       if (this.timeout) clearTimeout(this.timeout);
      //       this.timeout = setTimeout(() => {
      //         this.setSelectAllDraft(false);
      //         this.selectedItems = [];
      //         this.isCheckedAll = false;
      //         this.selectedUpline = undefined;
      //         this.loadDraft(this.filterParams);
      //       }, 500);
      //     });
      //   }
      // });
    },
    submitSelected() {
      if (this.currentUser.usertype_elemen == "KRANI" && !this.selectedUpline) {
        return (this.showUplineDialog = true);
        //open upline selector
      }
      if (this.selectedItems.length > 1) {
        return this.$swal({
          title: this.$t("msgbox.warning"),
          text: this.$t("msgbox.cannot-submit-more-than-one-draft"),
          icon: "warning",
          showCancelButton: false,
          cancelButtonText: this.$t("button.ok"),
          customClass: {
            container: this.$vuetify.theme.dark ? "theme--dark" : "",
          },
        });
      }
      let formData = {
        id_hash: this.selectedItems[0].toString(),
        initiator_nik: this.selectedUpline.toString(),
      };

      this.submitDraftFromList({ formData: formData });
      this.$nextTick(() => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.setSelectAllDraft(false);
          this.selectedItems = [];
          this.isCheckedAll = false;
          this.selectedUpline = undefined;
          this.loadDraft(this.filterParams);
          this.$store.commit("SET_CREATE_GENERAL_MEMO", false);
        }, 500);
      });
    },
    updateCheckAll() {
      if (
        this.selectedItems.length == this.memoIds.length &&
        this.selectedItems.length > 0 &&
        !this.selectAllDraft
      ) {
        this.setSelectAllDraft(true);
      } else {
        this.setSelectAllDraft(false);
      }
    },
    cutJudul(str, strLen) {
      if (this._deviceType() == "smartphone") {
        return this.strCutStripped(str, strLen);
      } else {
        return this.strCutStripped(str, strLen);
      }
    },
    cutIsi(str, strLen) {
      if (this._deviceType() == "smartphone") {
        return this.strCutStripped(str, strLen);
      } else {
        return this.strCutStripped(str, strLen);
      }
    },
    submitStatus(val) {
      if (!val) return "pending";
      if ([10, 20, 30].includes(parseInt(val))) return "draft";
      let karpimIsCreator = [
        "KARPIM",
        "KARPIM_KANPUS",
        "KASUBAG",
        "KADIS",
      ].includes(this.currentUser.usertype_elemen);
      if (karpimIsCreator && [25, 35].includes(parseInt(val)) === true) {
        return "pending";
      }
      return "submitted";
    },
    loadDraftX(params){
      this.editXParams = params;
    },
    changeXParams(val){
      this.editXParams.show = val;
    }
  },
};
</script>